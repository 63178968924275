import React from 'react'
import Layout from '../components/Layout'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import Seo from '../components/Seo'


const Costopt = () => {
    return (
        <Layout>
            <Seo  title="AWS Cost Optimalisatie"/>
            <main className="page">
            <section className="about-page">
                <article>
                <h5>Verlaag jouw AWS Rekening</h5>
                    <p>
                    Heb jij de kosten voor AWS onder controle? 
                    </p>
                    <p>
                    Samen met jou gaan we aan de slag om jouw AWS rekening te verlagen. 
                    </p>
                    <p>
                    Via onze Cost Optimalisatie aanpak kun je direct de eerste besparingen inboeken. 
                    <a href={'AWS_Cost_Optimalisatie.pdf'} brochure target="_blank" rel="noreferrer"> Brochure Cost Optimalisatie</a>.
                    </p>
                    <Link to="/contact" className="btn">
                        contact
                    </Link>
                </article>
                <StaticImage 
                src="../assets/images/projectsphoto.jpg" 
                alt="People working together" 
                className = "about-img"
                placeholder ="blurred"/>
            </section>
            </main>
        </Layout>
    )
}

export default Costopt